// https://formkit.com/essentials/installation#default-theme

import {
  createAutoAnimatePlugin,
  createAutoHeightTextareaPlugin,
  createMultiStepPlugin,
} from "@formkit/addons";
import { de, en, es, fr, it, pt, tr } from "@formkit/i18n";
import { genesisIcons, icons } from "@formkit/icons";
import { createProPlugin, inputs } from "@formkit/pro";
import { createThemePlugin } from "@formkit/themes";
import { createInput, type DefaultConfigOptions } from "@formkit/vue";
import { addAsteriskPlugin } from "mws-utils/lib/formkitPlugins";

import BasicEditor from "@/common/components/BasicEditor.vue";
import BasicTagsList from "@/common/components/BasicTagsList.vue";
import CentsInputVue from "@/common/components/CentsInput.vue";
import SlugInputVue from "@/common/components/SlugInput.vue";
import UploaderInput from "@/common/components/UploaderInput.vue";
import {
  addFormAuthorizationPlugin,
  addUnsavedChangesMessagePlugin,
  clearErrorsOnSubmitPlugin,
  removeNonResolvedIconsPlugin,
  scrollToErrors,
  validationErrorsPlugin,
} from "@/plugins/formKitPlugins";
import ProductLabelInput from "@/products/productLabels/components/ProductLabelInput.vue";

import { rootClasses } from "./formkit.theme";

const basicEditor = createInput(BasicEditor, {
  props: ["editorContent"],
  family: "text",
});
const basicTagsList = createInput(BasicTagsList, {
  props: ["tags"],
  family: "tags",
});
const cents = createInput(CentsInputVue, {
  props: ["currencySymbol", "applyAutoFormat", "percentage"],
  family: "text",
});
const slug = createInput(SlugInputVue, { family: "text" });
const uploader = createInput(UploaderInput, {
  props: {
    previewFull: {
      default: false,
    },
    initialFile: {
      default: undefined,
    },
    multiple: {
      default: false,
    },
    hideName: {
      default: false,
    },
    hideSideIcons: {
      default: false,
    },
  },
});
const productLabels = createInput(ProductLabelInput);

const formkitPro = createProPlugin("fk-9089390677", inputs);

const config: DefaultConfigOptions = {
  config: {
    rootClasses,
  },
  inputs: {
    basicEditor,
    basicTagsList,
    cents,
    slug,
    uploader,
    productLabels,
  },
  plugins: [
    scrollToErrors,
    createThemePlugin(),
    createAutoAnimatePlugin(),
    createAutoHeightTextareaPlugin(),
    addUnsavedChangesMessagePlugin,
    addFormAuthorizationPlugin,
    removeNonResolvedIconsPlugin,
    validationErrorsPlugin,
    clearErrorsOnSubmitPlugin,
    formkitPro,
    createMultiStepPlugin(),
    addAsteriskPlugin,
  ],
  icons: {
    ...icons,
    ...genesisIcons,
  },
  locales: { en, de, fr, es, it, pt, tr },
  locale: navigator.language.split("-")[0],
};

export default config;
