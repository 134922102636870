<script setup lang="ts">
import {
  AdjustmentsHorizontalIcon,
  BanknotesIcon,
  BriefcaseIcon,
  CalendarIcon,
  CpuChipIcon,
  CreditCardIcon,
  CurrencyDollarIcon,
  HeartIcon,
  HomeIcon,
  NewspaperIcon,
  PaperAirplaneIcon,
  PhotoIcon,
  RectangleGroupIcon,
  ShieldCheckIcon,
  ShoppingBagIcon,
  ShoppingCartIcon,
  Square3Stack3DIcon,
  SwatchIcon,
  TrophyIcon,
  TruckIcon,
  UserGroupIcon,
  UsersIcon,
} from "@heroicons/vue/24/outline";
import { computed, type FunctionalComponent } from "vue";

import DropdownVue from "@/common/components/DropdownVue.vue";
import MenuLink from "@/common/components/menu/MenuLink.vue";
import { useScreenSize } from "@/common/composables/useScreenSize";
import type { NavigationLink } from "@/common/types";
import type { Permission } from "@/permissions/types";
import PlatformPicker from "@/platforms/components/PlatformPicker.vue";
import { useCurrentUser } from "@/users/public/composables";

const { userHasPermission } = useCurrentUser();

const strapiAdminUrl = import.meta.env.VITE_STRAPI_ADMIN_URL;

const globalNavigationLinks: NavigationLink[] = [
  {
    link: "/",
    name: "Home",
    icon: HomeIcon,
    permissionsNeeded: ["Admin.Access"] as Permission[],
  },
  {
    link: "/platforms",
    name: "Platforms",
    icon: Square3Stack3DIcon,
    permissionsNeeded: ["Admin.Access"] as Permission[],
  },
];

const monitor = useScreenSize();

const timeFrame = computed(() => (monitor.isMobile.value ? "day" : "week"));

const mwsNavigationLinks = computed(() => [
  {
    link: "/customers",
    name: "Customers",
    icon: UsersIcon,
    permissionsNeeded: ["Admin.Access"] as Permission[],
  },
  {
    link: `/events/calendar/${timeFrame.value}?statuses=["Published","Draft","Confirmed"]`,
    name: "Events",
    icon: CalendarIcon,
    permissionsNeeded: ["Admin.Access"] as Permission[],
  },
  {
    link: "/bids",
    name: "Bids",
    icon: BanknotesIcon,
    permissionsNeeded: ["Admin.Access"] as Permission[],
  },
  {
    link: "/beneficiaries",
    name: "Beneficiaries",
    icon: HeartIcon,
    permissionsNeeded: ["Admin.Access"] as Permission[],
  },
  {
    link: "/orders",
    name: "Orders",
    icon: CreditCardIcon,
    permissionsNeeded: ["Admin.Access"] as Permission[],
  },
  {
    link: "/permissions/roles",
    name: "Role Management",
    icon: ShieldCheckIcon,
    permissionsNeeded: ["Developer.Access"] as Permission[],
  },
  {
    link: "/categories",
    name: "Categories",
    icon: SwatchIcon,
    permissionsNeeded: ["Categories.Read"] as Permission[],
  },
  {
    link: strapiAdminUrl,
    name: "Strapi",
    icon: NewspaperIcon,
    permissionsNeeded: ["Articles.Read", "Articles.Write"] as Permission[],
    isExternalLink: true,
  },
  {
    link: "/admin-tools",
    name: "Admin Tools",
    icon: AdjustmentsHorizontalIcon,
    permissionsNeeded: ["Developer.Access"] as Permission[],
  },
  {
    icon: PaperAirplaneIcon,
    link: "/pick-pack-ship",
    name: "Pick, Pack & Ship",
    permissionsNeeded: ["Admin.Access"] as Permission[],
  },
]);

const framingNavigationLinks: NavigationLink[] = [
  {
    link: "/framing/orders",
    name: "Orders",
    icon: CreditCardIcon,
    permissionsNeeded: [
      "FramingAdmin.Access",
      "FramingOrders.Read",
    ] as Permission[],
  },
  {
    link: "/framing/offers",
    name: "Offers",
    icon: BriefcaseIcon,
    permissionsNeeded: [
      "FramingAdmin.Access",
      "FramingOffers.Read",
    ] as Permission[],
  },
  {
    link: "/framing/configuration-templates",
    name: "Configuration Templates",
    icon: RectangleGroupIcon,
    permissionsNeeded: [
      "FramingAdmin.Access",
      "ConfigurationTemplates.Read",
    ] as Permission[],
  },
  {
    link: "/framing/passe-partouts",
    name: "Passe-partouts",
    icon: PhotoIcon,
    permissionsNeeded: ["FramingAdmin.Access"] as Permission[],
  },
  {
    link: "/framing/mockup-sets",
    name: "Mockup Sets",
    icon: PhotoIcon,
    permissionsNeeded: ["FramingAdmin.Access"] as Permission[],
  },

  {
    link: "/framing/feature-options",
    name: "Feature Options",
    icon: SwatchIcon,
    permissionsNeeded: ["Developer.Access"] as Permission[],
  },
];

const fabricksNavigationLinks: NavigationLink[] = [
  {
    link: "/fabricks/chips",
    name: "Chips",
    icon: CpuChipIcon,
    permissionsNeeded: ["FabricksAdmin.Access"] as Permission[],
  },
  {
    link: "/fabricks/partners",
    name: "Partners",
    icon: UserGroupIcon,
    permissionsNeeded: ["FabricksAdmin.Access"] as Permission[],
  },
  {
    // trackable products
    link: "/fabricks/trackable-products",
    name: "Trackable Products",
    icon: ShoppingCartIcon,
    permissionsNeeded: ["FabricksAdmin.Access"] as Permission[],
  },
];

const filteredGlobalNavigationLinks = computed(() => {
  return globalNavigationLinks.filter((navigationLink) =>
    userHasPermission(navigationLink.permissionsNeeded),
  );
});

const filteredMwsNavigationLinks = computed(() => {
  return mwsNavigationLinks.value.filter((navigationLink) =>
    userHasPermission(navigationLink.permissionsNeeded),
  );
});

const filteredFramingNavigationLinks = computed(() => {
  return framingNavigationLinks.filter((navigationLink) =>
    userHasPermission(navigationLink.permissionsNeeded),
  );
});

const filteredFabricksNavigationLinks = computed(() => {
  return fabricksNavigationLinks.filter((navigationLink) =>
    userHasPermission(navigationLink.permissionsNeeded),
  );
});

const subMenuItems: {
  name: string;
  permissionsNeeded?: Permission[];
  icon: FunctionalComponent;
  subItems: {
    link: string;
    name: string;
  }[];
}[] = [
  {
    name: "Product management",
    icon: ShoppingBagIcon,
    subItems: [
      {
        link: "/product-listings",
        name: "Product Listings ",
      },
      {
        link: "/products",
        name: "Products (new)",
      },
      {
        link: "/product-types",
        name: "Types",
      },
      {
        link: "/labels",
        name: "Labels",
      },
    ],
  },
  {
    name: "Finance",
    icon: CurrencyDollarIcon,
    subItems: [
      {
        link: "/partners",
        name: "Partners",
      },
      {
        link: "/vendors",
        name: "Vendors",
      },
      {
        link: "/legal-organisations",
        name: "Legal Organisations",
      },
      {
        link: "/adyen-merchants",
        name: "Adyen Merchants",
      },
    ],
  },
  {
    name: "Shipping",
    icon: TruckIcon,
    subItems: [
      {
        link: "/global-shipping-configs",
        name: "Global Shipping Configs",
      },
      {
        link: "/parcel-types",
        name: "Parcel types",
      },
      {
        link: "/fabricks/chip-scans",
        name: "Find order (NFC)",
      },
    ],
  },
  {
    name: "Sports management",
    icon: TrophyIcon,
    subItems: [
      {
        link: "/sports",
        name: "Sports",
      },
      {
        link: "/teams",
        name: "Teams",
      },
      {
        link: "/athletes",
        name: "Athletes",
      },
      {
        link: "/leagues",
        name: "Leagues",
      },
    ],
  },
  {
    name: "Sports management V2",
    permissionsNeeded: ["Sports.Read"] as Permission[],
    icon: TrophyIcon,
    subItems: [
      {
        link: "/sports-v2",
        name: "Sports",
      },
      {
        link: "/teams-v2",
        name: "Teams",
      },
      {
        link: "/athletes-v2",
        name: "Athletes",
      },
    ],
  },
];

const filteredSubMenuItems = computed(() =>
  subMenuItems.filter((menuItem) =>
    !menuItem.permissionsNeeded
      ? true
      : userHasPermission(menuItem.permissionsNeeded),
  ),
);

defineEmits<{
  (e: "close-sidebar-click"): void;
}>();
</script>

<template>
  <div>
    <PlatformPicker
      v-if="userHasPermission('GlobalUser.Access')"
      class="mb-4"
    />

    <div
      v-if="filteredGlobalNavigationLinks.length > 0"
      class="sidebar-links__global-section"
    >
      <MenuLink
        v-for="navigationLink in filteredGlobalNavigationLinks"
        :key="navigationLink.name"
        :navigation-link="navigationLink"
      />
    </div>

    <div class="sidebar-links__marketplace-section">
      <span class="sidebar-links__nav-section-title">MatchWornShirt</span>
      <MenuLink
        v-for="navigationLink in filteredMwsNavigationLinks"
        :key="navigationLink.name"
        :navigation-link="navigationLink"
        :data-cy="`vue-navigation-link-${navigationLink.name
          .toLowerCase()
          .replace(' ', '-')}`"
        @click="$emit('close-sidebar-click')"
      />
      <DropdownVue
        v-for="subMenuItem in filteredSubMenuItems"
        :key="subMenuItem.name"
        :title="subMenuItem.name"
        :options="subMenuItem.subItems"
        :icon="subMenuItem.icon"
        :data-cy="`vue-navigation-dropdown-${subMenuItem.name
          .toLowerCase()
          .replace(' ', '-')}`"
        @item-click="$emit('close-sidebar-click')"
      />
    </div>

    <div
      v-if="filteredFramingNavigationLinks.length > 0"
      class="sidebar-links__framing-section"
    >
      <span class="sidebar-links__nav-section-title">Frame the game</span>
      <MenuLink
        v-for="navigationLink in filteredFramingNavigationLinks"
        :key="navigationLink.name"
        :navigation-link="navigationLink"
        @click="$emit('close-sidebar-click')"
      />
    </div>

    <div
      v-if="filteredFabricksNavigationLinks.length > 0"
      class="sidebar-links__fabricks-section"
    >
      <span class="sidebar-links__nav-section-title">Fabricks</span>
      <MenuLink
        v-for="navigationLink in filteredFabricksNavigationLinks"
        :key="navigationLink.name"
        :navigation-link="navigationLink"
        @click="$emit('close-sidebar-click')"
      />
    </div>
  </div>
</template>

<style scoped lang="postcss">
.sidebar-links {
  &__marketplace-section {
    @apply pt-4 space-y-1 border-t border-gray-700;
  }

  &__framing-section {
    @apply pt-4 space-y-1 border-t border-gray-700;
  }

  &__fabricks-section {
    @apply pt-4 space-y-1 border-t border-gray-700;
  }

  &__nav-section-title {
    @apply text-gray-400 px-2 uppercase text-sm;
  }

  &__link {
    @apply text-gray-100 hover:bg-gray-700 hover:text-white flex items-center px-2 py-2 text-sm font-medium rounded-md justify-between;

    &__content {
      @apply flex items-center gap-x-4;

      &__icon {
        @apply text-gray-400 text-2xl;
      }
    }
  }
}
</style>
